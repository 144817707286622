/*
 * Copyright 2024 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
$reward-banner-bg: #00c853;
$reward-banner-hover-bg: #14e069;
$reward-banner-text-color: #333;
$reward-banner-border: #999;
$reward-banner-after-bg: rgba(220, 220, 220, 70%);

@-webkit-keyframes slide-from-under {
  from {
    height: 0;
  }

  to {
    height: 40px;
  }
}

@keyframes slide-from-under {
  from {
    height: 0;
  }

  to {
    height: 40px;
  }
}

.reward-banner {
  -webkit-animation: slide-from-under 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s both;
  animation: slide-from-under 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.25s both;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: calc(100% + 60px);
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  white-space: nowrap;
  font-family: sans-serif;
  font-weight: 600;
  background-color: $reward-banner-bg;
  color: $reward-banner-text-color;
  left: -30px;
  margin: -10px 0 10px 0;
  cursor: pointer;

  .hide {
    display: none;
  }

  &:hover {
    background-color: $reward-banner-hover-bg;
  }

  &::before {
    line-height: normal;
    padding: 2px 4px;
    margin-right: 4.5ex;
    font-size: 12px;
    content: "click to close, or press";
    float: right;
    margin-top: 10px;
    margin-left: -100%;
    font-weight: normal;
  }

  &::after {
    line-height: normal;
    content: "esc";
    font-size: 12px;
    padding: 1px 4px;
    border: 1px solid $reward-banner-border;
    border-radius: 2px;
    background-color: $reward-banner-after-bg;
    float: right;
    margin-top: 10px;
    margin-left: -100%;
    font-weight: normal;
  }
}
